import { useEffect, useState } from 'react';

/* Keep in sync _breakpoints.scss */
const breakpoints = {
  desktop: 996,
  monitor: 1140,
  phone: 480,
  tablet: 768,
  xlmonitor: 1920,
};

type BreakpointName = 'phone' | 'tablet' | 'desktop' | 'monitor' | 'lgmonitor' | 'xlmonitor';

type TResponse = {
  Responsive: {
    isBiggerThan: (breakpoint: BreakpointName) => boolean;
    isSmallerThan: (breakpoint: BreakpointName) => boolean;
  };
};

export default function useResponsive(): TResponse {
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    Responsive: {
      isBiggerThan: (breakpoint: BreakpointName) => {
        return breakpoints[breakpoint] < width;
      },
      isSmallerThan: (breakpoint: BreakpointName) => {
        return breakpoints[breakpoint] >= width;
      },
    },
  };
}
